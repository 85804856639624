.dir-heading {
    font-size: 4rem;
    line-height:1.2;
    font-weight: 700;
    text-wrap: balance;

    &__huge {
        font-size: 96px;
    }

    &__large {
        font-size: 62px;
    }

    &__small {
        font-weight: 600;
        font-size: 31px;
    }
}
