/* You can add global styles to this file, and also import other style files */

@import './assets/scss/colors';
@import './assets/scss/typography';
@import './assets/scss/mat-theme.scss';

// Directives
@import './app/domains/ui/ui';

html, body {
    height: 100%;
}

body {
    margin: 0;
}
