.dir-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: white;
    padding: 7px 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    border: 1px solid transparent;
    border-radius: 8px;
    width: max-content;
    min-width: 68px;
    text-decoration: none;

    app-svg-to-icon {
        display: flex;
        height: 20px;
        margin-bottom: 3px;
    }

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    &__full-width {
        width: 100%;
    }

    &__disabled, &:disabled {
        opacity: 0.6;

        &:hover {
            box-shadow: none;
            pointer-events: none;
            cursor: unset;
        }
    }

    &__secondary {
        background-color: white;
        color: var(--color-primary);
        border: 1px solid var(--color-border-grey);
    }
}
