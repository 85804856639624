.dir-paragraph {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;

    &__faded {
        opacity: 0.6;
    }

    &__bold {
        font-weight: 700;
    }

    &__medium {
        font-weight: 500;
    }

    &__large {
        font-size: 52px;
    }
}
