@font-face {
    font-family: "ITC Avant Garde Gothic";
    src:
        local("ITC Avant Garde Gothic LT"),
        url("/assets/fonts/ITC Avant Garde Gothic LT Book.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "ITC Avant Garde Gothic";
    src:
        local("ITC Avant Garde Gothic LT"),
        url("/assets/fonts/ITC Avant Garde Gothic LT Bold.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "ITC Avant Garde Gothic";
    src:
        local("ITC Avant Garde Gothic LT"),
        url("/assets/fonts/ITC Avant Garde Gothic LT Medium.woff") format("woff");
    font-weight: 500;
}

:root {
    --global-font-stack: "ITC Avant Garde Gothic", 'ui-sans-serif, system-ui';
}

h1, h2, h3, h4, h5, h6, p, span, a, label, input, textarea, select, button, li, ol {
    font-family: var(--global-font-stack);
    color: var(--color-text);
}

p, span, a {
    margin: 0;
    font-size: 16px;
}
