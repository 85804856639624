:root {
    --color-background-rgb: 13, 24, 61;
    --color-brand-rgb: 238, 45, 51;
    --color-text-rgb: 255, 255, 255;
    --color-warning-rgb: 255, 204, 71;
    --color-danger-rgb: 238, 45, 1;
    --color-success-rgb: 31, 194, 85;
    --color-background-success-rgb: 31, 194, 85;
    --color-background-warning-rgb: 255, 204, 71;
    --color-background-danger-rgb: 214, 69, 69;
    --color-border-grey-rgb: 211, 215, 224;

    --color-background: rgba( var(--color-background-rgb), 1);
    --color-brand: rgba( var(--color-brand-rgb), 1);
    --color-text: rgba( var(--color-text-rgb), 1);
    --color-warning: rgba( var(--color-warning-rgb), 1);
    --color-danger: rgba( var(--color-danger-rgb), 1);
    --color-success: rgba( var(--color-success-rgb), 1);
    --color-background-success: rgba( var(--color-background-success-rgb), .2);
    --color-background-warning: rgba( var(--color-background-warning-rgb), .1);
    --color-background-danger: rgba( var(--color-background-danger-rgb), .1);
    --color-border-grey: rgba( var(--color-border-grey-rgb), 1);

}
